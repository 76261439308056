<template>
  <v-btn v-bind="$attrs" :color="color" class="text-capitalize">
    <slot>
      <slot name="prefix"> </slot>
      <slot name="text"></slot>
      <slot name="suffix"> </slot>
    </slot>
  </v-btn>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'primary'
    }
  }
};
</script>

<style></style>
